@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #9b69d4;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

body {
  overflow-x: hidden;
  background-color: white;
}

.animated-border {
  position: relative;
  cursor: pointer; /* Added for a better UX on hover */
}

.animated-border::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #9b69d4;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.6s ease;
}

.animated-border:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

@import "suneditor/dist/css/suneditor.min.css";
